<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- filters and buttons area -->
    <!-- ************************************************************************************ -->
    <MetricFilterBox
      :defaultFilterData="metrics.states"
      v-model="metricsList"
      @clearFilters="clearFilters"
      @openMetric="saveMetrics"
      :clearBtnShowen="metricsList.length > 0"
    >
      <metricbox
        v-for="metric in metricsList"
        :key="metric.key"
        :label="$t(metric.label)"
        @close="closeMetric(metric, metric.filterKey)"
      >
        <template slot="filterBody">
          <component
            :is="metric.compName"
            v-model="filters[metric.key]"
            :meta="metric.meta ? metric.meta : null"
            @change="filtersChanged(metric.key, $event)"
          ></component>
        </template>
      </metricbox>
    </MetricFilterBox>

    <AgentsSheetsHandler
      :chosenFilters="filters"
      @dataUpdated="agentUpdated"
      @getSheetResult="getSheetResult"
    />

    <el-row :gutter="20" type="flex" justify="end">
      <el-col>
        <div class="u-display-flex u-justify-content--end">
          <el-button
            class="u-bottom-margin--1x"
            type="primary"
            @click="showCreateDialogFlag = true"
          >
            {{ $t("ActiveAgentsPageAddBtnLabel") }}
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- ************************************************************************************ -->
    <!-- Table Area -->
    <!-- ************************************************************************************ -->
    <template>
      <el-table :data="agentsList" border stripe>
        <el-table-column prop="agentEntityId" :label="`Entity ID`" />
        <el-table-column
          prop="phone"
          :label="$t('ActiveAgentsPageTabelColumnPhone')"
        />
        <el-table-column
          prop="name"
          :label="$t('ActiveAgentsPageTabelColumnName')"
        />
        <el-table-column
          prop="agentCode"
          :label="$t('ActiveAgentsPageTabelColumnCode')"
        />
        <el-table-column
          prop="areas"
          :label="$t('ActiveAgentsPageTabelColumnAreas')"
          width="180"
        >
          <template slot-scope="scope">
            <div>
              <div v-for="item in scope.row.areasV2" :key="item.id">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operations" :label="$t('GlobalOperationsLabel')">
          <template slot-scope="props">
            <div>
              <el-button
                type="danger"
                size="mini"
                @click="suspendClicked(props.row)"
              >
                {{ $t("ActiveAgentsPageTabelValueBtnSuspend") }}
              </el-button>
              <el-button
                type="success"
                size="mini"
                @click="editAgentClicked(props.row)"
              >
                {{ $t("ActiveAgentsPageTabelValueBtnEdit") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Visits">
          <template slot-scope="scope">
            <el-button
              type="success"
              size="mini"
              @click="handleShowVisitsManagement(scope.row)"
            >
              Edit
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <!-- ************************************************************************************ -->
    <!-- Pagination area -->
    <!-- ************************************************************************************ -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :total="pagination.count"
        :current-page="currentPage"
        :page-size="25"
        @current-change="pageChanged"
      ></el-pagination>
    </div>
    <!-- ************************************************************************************ -->
    <!-- Dialogs area -->
    <!-- ************************************************************************************ -->
    <AgentCreateDialog
      v-model="showCreateDialogFlag"
      @closeDialog="closeCreateDialogClicked"
      :editData="{}"
      @agentAdded="agentCreated"
    />
    <AgentUpdateDialog
      :showDialog="showUpdateDialogFlag"
      :editData="chosenRowForEdit"
      @closeDialog="closeDialogClicked"
      @agentUpdated="agentUpdated"
    />
    <el-dialog
      :title="$t('ActiveAgentsPageTabelColumnRetailers')"
      :visible.sync="showRetailersDialog"
    >
      <el-table :data="retailersDisplay" border stripe>
        <el-table-column
          prop="id"
          :label="$t('ActiveAgentsPageTabelColumnUserId')"
        />
        <el-table-column
          prop="name"
          :label="$t('ActiveAgentsPageTabelColumnName')"
        />
      </el-table>
    </el-dialog>
    <el-dialog
      class="leadDetailsDialog"
      title="Results"
      :visible.sync="resultsDialog"
      width="50%"
      @close="closeResultDialog"
    >
      <!-- faild Assign Result -->
      <ul
        class="assignedIdsErrorWrapper"
        :v-if="failedAssignRequests && failedAssignRequests.length > 0"
      >
        <li
          v-for="failedAssignRequest in failedAssignRequests"
          :key="failedAssignRequest.row"
        >
          <div>
            <i class="el-icon-warning"></i>
            <span>
              Row: {{ failedAssignRequest.row }}
              {{ failedAssignRequest.error }}
            </span>
          </div>
        </li>
      </ul>
      <!-- faild Assign Result -->
    </el-dialog>
    <el-dialog
      title="Sales Visits Management"
      :visible.sync="showVisitsManagementFlag"
      @close="handleCloseVisitsManagement"
      width="40%"
    >
      <template slot="title">
        <el-row type="flex" justify="space-between">
          <el-col>
            <h2>Sales Visits Management</h2>
            <p>{{ selectedAgent?.name }}</p>
          </el-col>
        </el-row>
      </template>
      <template slot="footer">
        <el-row type="flex" align="middle" justify="space-around">
          <el-upload
            class="u-right-margin--2x u-left-margin--2x"
            :on-change="
              (changedData) => uploadSheetFileChanged(changedData, 'append')
            "
            :on-remove="removeSheetFile"
            action="#"
            :multiple="false"
            :auto-upload="false"
            :file-list="sheetSingleAssignFileList"
            accept=".xlsx"
            :show-file-list="false"
          >
            <el-button slot="trigger" type="success"> Append Visits </el-button>
          </el-upload>
          <el-button type="success" @click="handleUnassignedAllVisits"
            >Unassigned All Visits</el-button
          >
        </el-row>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { faturaTeamService } from "@/services/faturaTeam.service.js";
import { mapGetters } from "vuex";
import AgentCreateDialog from "./AgentsCreateDialog.vue";
import AgentUpdateDialog from "./AgentsUpdateDialog.vue";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { salesAgentsMetrics } from "@/components/transaction/metricboxComponents";
import { remove, isEmpty } from "lodash";
import AgentsSheetsHandler from "./AgentsSheetsHandler";
export default {
  name: "ActiveAgentsPage",
  components: {
    AgentCreateDialog,
    AgentUpdateDialog,
    MetricFilterBox,
    metricbox,
    ...salesAgentsMetrics,
    AgentsSheetsHandler,
  },
  props: {
    isHasPermission: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      agentsList: [],
      pagination: {},
      filters: { suspended: false },
      showCreateDialogFlag: false,
      showUpdateDialogFlag: false,
      originalData: [],
      chosenRowForEdit: null,
      retailersDisplay: [],
      metricsList: [],
      metrics: {
        states: [
          {
            key: "phone",
            label: "SalesAgentsPageFilterLabelPhone",
            compName: "stringMetric",
          },

          {
            key: "areas",
            label: "SalesAgentsPageFilterLabelAreas",
            compName: "areaMetric",
          },
          {
            key: "regions",
            label: "SalesAgentsPageFilterLabelRegions",
            compName: "regionChildrenMetric",
          },
          {
            key: "agentCode",
            label: "SalesAgentsPageFilterLabelAgentCode",
            compName: "stringMetric",
          },
        ],
      },
      resultsDialog: false,
      failedAssignRequests: [],
      successAssignRequest: 0,
      showVisitsManagementFlag: false,
      selectedAgent: null,
      sheetSingleAssignFileList: [],
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    areas() {
      return [];
    },
    currentSalesId() {
      return this.$route.query?.agentCode;
    },
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? parseInt(currentPageQuery) : 1;
    },

    showRetailersDialog: {
      get: function () {
        return this.retailersDisplay.length > 0;
      },
      set: function () {
        this.retailersDisplay = [];
      },
    },
  },
  watch: {
    $route(to) {
      this.getSalesAgents(to.query.page, this.filters);
    },
  },
  methods: {
    async agentUpdated() {
      this.closeDialogClicked();
      await this.getSalesAgents(this.currentPage, this.filters);
    },
    closeDialogClicked() {
      this.showUpdateDialogFlag = false;
      this.chosenRowForEdit = null;
    },
    async editAgentClicked(row) {
      try {
        const response = await faturaTeamService.getSalesAgentsById(row.agentEntityId);
        this.chosenRowForEdit = response.data.data;
        this.showUpdateDialogFlag = true;
        
      } catch (error) {
        return error;
      }
    },
    closeCreateDialogClicked() {
      this.showCreateDialogFlag = false;
    },
    async getSalesAgents(page = 1, chosenFilters) {
      const filters = chosenFilters;
      try {
        const response = await faturaTeamService.getSalesAgents(page, filters);
        const responseData = response.data.data;
        this.agentsList = responseData.result;
        this.originalData = JSON.parse(JSON.stringify(responseData.result));
        this.pagination = {
          count: responseData.count,
          next: responseData.next,
          pages: responseData.pages,
        };
      } catch (error) {
        if (error.response.status == 403) this.isHasPermission(false);
      }
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    async agentCreated() {
      this.showCreateDialogFlag = false;
      if (this.$route.fullPath != "/entities/faturaTeam/salesAgents?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
      } else {
        this.getSalesAgents(this.currentPage, this.filters);
      }
    },
    suspendClicked(clickedRow) {
      this.$confirm(
        this.$t("ActiveAgentsPageSuspendConfirmMessage", [clickedRow.name]),
        this.$t("GlobalWarningLabel"),
        {
          confirmButtonText: this.$t("GlobalOkBtn"),
          cancelButtonText: this.$t("GlobalCancelBtn"),
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const userId = clickedRow.agentId;
            const updateBody = {
              suspended: true,
            };
            // return;
            const response = await faturaTeamService.changeUserProfileStatus(
              userId,
              updateBody
            );
            if (response && response.status == 200) {
              this.popupMessageWrapper(
                this.$t("ActiveAgentsPageSuspendSuccessMessage"),
                "success",
                2000
              );
              setTimeout(() => {
                this.getSalesAgents(this.currentPage, this.filters);
              }, 1500);
            }
            console.log("response", response.data);
          } catch (error) {
            const errorMessage = this.errorMessageExtractor(error);
            this.popupMessageWrapper(errorMessage, "error", 2000);
          }
        })
        .catch(() => {});
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      const errorMessageExistsMessage =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      let finalErrorMessage = this.$t("GlobalErrorMessage");
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      if (errorMessageExistsMessage) {
        finalErrorMessage = errorObject.response.data.message;
      }
      return finalErrorMessage;
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    clearFilters() {
      this.filters = { suspended: false };
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.salesAgents = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metricsSalesAgents", []);
      if (this.$route.fullPath != "/entities/faturaTeam/salesAgents?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getSalesAgents(this.currentPage, { ...this.filters });
    },
    saveMetrics() {
      localStorage.setItem(
        "metricsSalesAgents",
        JSON.stringify(this.metricsList)
      );
    },
    closeMetric(metric) {
      remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem(
        "metricsSalesAgents",
        JSON.stringify(this.metricsList)
      );
      this.metricsList = [...this.metricsList];
    },
    filtersChanged(key, value) {
      if (!(typeof value === "number") && isEmpty(value)) {
        delete this.filters[key];
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.salesAgents = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      if (this.$route.fullPath != "/entities/faturaTeam/salesAgents?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getSalesAgents(this.currentPage, { ...this.filters });
    },
    initialFiltersHandler() {
      let filtersSession = sessionStorage.getItem("filter");
      if (filtersSession) {
        filtersSession = JSON.parse(filtersSession);
        if (filtersSession.salesAgents) {
          const filtersObject = filtersSession.salesAgents;
          if (filtersObject && filtersObject.filters)
            this.filters = { ...filtersObject.filters, suspended: false };
        }
      }
      let metricsSession = localStorage.getItem("metricsSalesAgents");
      if (metricsSession) {
        this.metricsList = JSON.parse(metricsSession);
      }
    },
    closeResultDialog() {
      this.failedAssignRequests = [];
      this.failedDeclinedRequests = [];
    },
    getSheetResult(data) {
      if (data.failed.length > 0) {
        this.resultsDialog = true;
      }
      this.failedAssignRequests = data.failed;
      this.successAssignRequest = data.success;
    },
    handleShowVisitsManagement(row) {
      this.selectedAgent = row;
      this.showVisitsManagementFlag = true;
    },
    async uploadSheetFileChanged(changedData, action) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", changedData.raw);
      try {
        const response = await faturaTeamService.importVisitsSheet(
          toBeSentForm,
          action
        );
        this.getSheetResult(response.data);
        if (response.data.failed.length === 0) {
          this.$message({
            message: "import file successfully",
            type: "success",
            duration: 2000,
          });
        }
        this.showVisitsManagementFlag = false;
        this.sheetSingleAssignFileList = [];
      } catch {
        this.sheetSingleAssignFileList = [];
      }
    },
    removeSheetFile() {
      this.sheetSingleAssignFileList = [];
    },
    handleCloseVisitsManagement() {
      this.showVisitsManagementFlag = false;
      this.selectedAgent = null;
      this.sheetSingleAssignFileList = [];
    },
    async handleUnassignedAllVisits() {
      this.$confirm(
        `Are you sure you want to unassign ALL VISITS FROM ${this.selectedAgent?.agentEntityId}`,
        "Warning",
        {
          confirmButtonText: "Confirm",
        }
      ).then(async () => {
        try {
          await faturaTeamService.unassignedAllVisits({
            agentIds: [this.selectedAgent?.agentEntityId],
          });
          this.$message({
            message: "Operation Success",
            type: "success",
            duration: 2000,
          });
        } catch {
          return false;
        }
      });
    },
  },
  async mounted() {
    if (this.currentSalesId) {
      this.filters = { ...this.filters, agentCode: this.currentSalesId };
    }
    this.initialFiltersHandler();
    this.getSalesAgents(this.currentPage, this.filters);
  },
};
</script>

<style></style>
