<template>
  <el-dialog
    :title="$t('ActiveAgentsPageDialogLabel')"
    :visible.sync="value"
    @close="closeDialogAction"
    @update:visible="closeDialogAction"
    width="50%"
  >
    <el-form :rules="rules" ref="salesAgentDialogRef" :model="dialogFormData">
      <el-form-item
        :label="$t('ActiveAgentsPageDialogFieldLabelName')"
        prop="name"
        :label-width="labelWidth"
      >
        <el-input
          size="mini"
          v-model="dialogFormData.name"
          autocomplete="off"
          :disabled="false"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('ActiveAgentsPageDialogFieldLabelPhone')"
        prop="phone"
        :label-width="labelWidth"
      >
        <el-input
          size="mini"
          v-model="dialogFormData.phone"
          autocomplete="off"
          :disabled="false"
          type="number"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('ActiveAgentsPageDialogFieldLabelCode')"
        prop="agentCode"
        :label-width="labelWidth"
      >
        <el-input
          size="mini"
          v-model="dialogFormData.agentCode"
          autocomplete="off"
          :disabled="false"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('ActiveAgentsPageDialogFieldLabelAreas')"
        prop="areas"
        :label-width="labelWidth"
      >
        <el-select
          style="width: 100% !important"
          v-model="dialogFormData.areas"
          clearable
          filterable
          multiple
        >
          <el-option
            v-for="item in lookupAreas"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="wallet limit "
        prop="walletLimit"
        :label-width="labelWidth"
      >
        <el-input
          size="mini"
          v-model.number="dialogFormData.walletLimit"
          autocomplete="off"
          :disabled="false"
          type="number"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelClicked">{{ $t("GlobalsCancelBtn") }}</el-button>
      <el-button type="success" @click="saveClicked">{{
        $t("GlobalsSaveBtn")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { entitiesServices } from "../../../../services/entities.service";
import { faturaTeamService } from "../../../../services/faturaTeam.service.js";

export default {
  name: "AgentCreateDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      labelWidth: "160px",
      fetchedRetailers: [],
      loading: false,
      rules: {
        name: [
          {
            required: true,
            message: `Name is required`,
            trigger: "blur",
          },
          {
            min: 5,
            message: `Length should be at least 5`,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: `Phone Number is required`,
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: `Phone number length should be 11`,
            trigger: "blur",
          },
        ],
        areas: [
          {
            required: true,
            message: `Areas are required`,
            trigger: "blur",
          },
        ],
        walletLimit: [
          {
            required: true,
            message: `Wallet Limit is required`,
            trigger: "blur",
          },
          {
            pattern: /(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\.?\d*$)/,
            message: `wallet Limit must be positive number`,
            trigger: "blur",
          },
        ],
      },
      defaultCreateData: {
        name: null,
        phone: null,
        agentCode: null,
        areas: null,
        walletLimit: 0,
      },
      dialogFormData: {},
    };
  },
  methods: {
    cancelClicked() {
      this.closeDialogAction();
    },
    closeDialogAction() {
      this.dialogFormData = JSON.parse(JSON.stringify(this.defaultCreateData));
      this.$emit("input", false);
    },
    async saveClicked() {
      console.log("dialogFormData", this.dialogFormData.walletLimit);
      try {
        let validFlag = true;
        this.$refs["salesAgentDialogRef"].validate((isValid) => {
          if (!isValid) {
            validFlag = false;
          }
        });
        if (!validFlag) {
          return;
        }
        let apiObject = {
          name: this.dialogFormData.name,
          phone: this.dialogFormData.phone,
          agentCode: this.dialogFormData.agentCode,
          areas: this.dialogFormData.areas,
          walletLimit: this.dialogFormData.walletLimit,
        };
        const response = await faturaTeamService.addSalesAgent(apiObject);
        if (response && response.status == 200) {
          this.popupMessageWrapper(
            this.$t("ActiveAgentsPageDialogCreateSuccesMessage"),
            "success",
            1500
          );
          setTimeout(() => {
            this.dialogFormData = JSON.parse(
              JSON.stringify(this.defaultCreateData)
            );
            this.$emit("agentAdded");
          }, 1000);
        }
      } catch (error) {
        const errorMessage = this.errorMessageExtractor(error);
        this.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    async remoteMethod(query) {
      if (query !== "") {
        const filtersObject = {
          storeName: query,
          activity: ["consumes"],
        };
        if (this.dialogFormData.areas && this.dialogFormData.areas.length > 0) {
          filtersObject.areaIds = this.dialogFormData.areas;
        }
        try {
          this.loading = true;
          const res = await entitiesServices.fetchEntities(1, filtersObject);
          if (res) {
            this.fetchedRetailers = res.data.data.entities;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
        this.loading = false;
      }
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      const errorMessageExistsMessage =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      let finalErrorMessage = this.$t(
        "RetailerWalletsPageWalletDefaultErrorMessage"
      );
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      if (errorMessageExistsMessage) {
        finalErrorMessage = errorObject.response.data.message;
      }
      return finalErrorMessage;
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    lookupAreas() {
      const lookupsObject = this.lookups;
      let areasArray = [];
      if (lookupsObject && lookupsObject.areas) {
        areasArray = JSON.parse(JSON.stringify(lookupsObject.areas));
      }
      return areasArray;
    },
  },
};
</script>

<style></style>
