<template>
  <div>
    <div
      class="buttons-container u-display-flex u-justify-content--between u-algin-items--center u-bottom-margin--2x"
    >
      <el-upload
        class="u-right-margin--2x u-left-margin--2x"
        :on-change="uploadSheetFileChanged"
        :on-remove="removeSheetFile"
        action="#"
        :multiple="false"
        :auto-upload="false"
        :file-list="sheetFileList"
        accept=".xlsx, .csv"
        :show-file-list="true"
      >
        <el-button slot="trigger" size="mini" type="primary">
          {{ $t("GlobalSelectFileButtonLabel") }}
        </el-button>
        <el-dropdown size="medium" @command="handleSheetImport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-top"
            type="info"
            plain
            :disabled="sheetFileList.length == 0"
            :title="sheetFileList.length == 0 ? 'true' : 'false'"
          >
            {{ $t("GlobalImportExcelButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="create">Create</el-dropdown-item>
            <el-dropdown-item command="update"
              >Update Agent Data</el-dropdown-item
            >
            <el-dropdown-item command="assign"
              >Update Agent Assignation</el-dropdown-item
            >
            <!-- <el-dropdown-item command="visits">Assign Visits</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </el-upload>
      <div>
        <el-dropdown size="medium">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            Handle Visits
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="">
              <el-upload
                :on-change="
                  (changedData) =>
                    uploadVisitsSheetFileChanged(changedData, 'append')
                "
                :on-remove="removeSheetFile"
                action="#"
                :multiple="false"
                :auto-upload="false"
                :file-list="visitsSheetFileList"
                accept=".xlsx"
                :show-file-list="false"
              >
                <el-button slot="trigger" size="mini" type="info" plain>
                  Append Visits
                </el-button>
              </el-upload>
            </el-dropdown-item>
            <el-dropdown-item command="">
              <el-button
                type="info"
                size="mini"
                plain
                @click="
                  () => {
                    showFilterVisitsDialog = true;
                  }
                "
                >Export All Visits</el-button
              >
            </el-dropdown-item>
            <el-dropdown-item command="">
              <el-upload
                :on-change="handleUploadUnassignVisits"
                :on-remove="removeSheetFile"
                action="#"
                :multiple="false"
                :auto-upload="false"
                :file-list="visitsSheetFileList"
                accept=".xlsx"
                :show-file-list="false"
              >
                <el-button slot="trigger" size="mini" type="info" plain>
                  Unassign Visits
                </el-button>
              </el-upload>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown size="medium" @command="handleSheetExport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            {{ $t("GlobalExportFileButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ type: 'agent', action: 'update' }"
              >Agent Data</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown size="medium" @command="handleSheetExport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            {{ $t("GlobalTemplateDownloadButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="{ type: 'agent', action: 'create' }"
              >Agent Create Template</el-dropdown-item
            >
            <el-dropdown-item
              :command="{ type: 'assignation', action: 'assignTemplate' }"
              >Agent Assignation Template</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown size="medium">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            Visits Template Download
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button
                size="mini"
                type="info"
                plain
                @click="
                  () => {
                    handleExportVisitsTemplate('assign', 'visits-template');
                  }
                "
              >
                Assign Visits Template
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button
                size="mini"
                type="info"
                plain
                @click="handleDownloadUnassignVisits"
              >
                Unassign Visits Template
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown size="medium">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            Handle Targets
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button
                size="mini"
                type="info"
                plain
                @click="
                  () => {
                    handelDownloadTargets('assign', 'target-template');
                  }
                "
                style="width: 100%; display: block"
              >
                Download Template
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item style="display: block">
              <el-upload
                :on-change="
                  (changedData) => uploadTargetSheetFileChanged(changedData)
                "
                :on-remove="removeSheetFile"
                action="#"
                :multiple="false"
                :auto-upload="false"
                :file-list="targetsSheetFileList"
                accept=".xlsx"
                :show-file-list="false"
              >
                <el-button slot="trigger" size="mini" type="info" plain>
                  Append Target
                </el-button>
              </el-upload>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-button
                size="mini"
                type="info"
                plain
                @click="
                  () => {
                    handelDownloadTargets('view', 'targets-sheet');
                  }
                "
                style="width: 100%; display: block"
              >
                Export Targets
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown size="medium">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-bottom"
            type="info"
            plain
          >
            Handle Areas
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button
                size="mini"
                type="info"
                plain
                @click="
                  () => {
                    handelDownloadAreas('template', 'Areas');
                  }
                "
                style="width: 100%; display: block"
              >
                Export Areas
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-upload
                :on-change="
                  (changedData) => handelImportAreas(changedData, 'assign')
                "
                :on-remove="removeSheetFile"
                action="#"
                :multiple="false"
                :auto-upload="false"
                :file-list="areasSheetFileList"
                accept=".xlsx"
                :show-file-list="false"
              >
                <el-button slot="trigger" size="mini" type="info" plain>
                  Assign Agent Areas
                </el-button>
              </el-upload>
            </el-dropdown-item>
            <el-dropdown-item>
              <el-upload
                :on-change="
                  (changedData) => handelImportAreas(changedData, 'unassign')
                "
                :on-remove="removeSheetFile"
                action="#"
                :multiple="false"
                :auto-upload="false"
                :file-list="areasSheetFileList"
                accept=".xlsx"
                :show-file-list="false"
              >
                <el-button slot="trigger" size="mini" type="info" plain>
                  Unassign Agent Areas
                </el-button>
              </el-upload>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog title="Filter Visits" :visible.sync="showFilterVisitsDialog">
      <el-form ref="filterVisits" label-position="top" :model="filterVisits">
        <el-row type="flex" style="flex-wrap: wrap" :gutter="15">
          <el-col :span="12">
            <el-form-item label="Start Date">
              <el-date-picker
                format="yyyy/MM/dd"
                style="width: 100%"
                placeholder="Start Date"
                v-model="filterVisits.startDate"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="End Date">
              <el-date-picker
                format="yyyy/MM/dd"
                style="width: 100%"
                placeholder="End Date"
                v-model="filterVisits.endDate"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Select Regions">
              <dropdownCategoriesSelect
                @change="regionsChanged"
                v-model="regionsId"
                style="width: 100%"
              />
              <!-- <el-button
                class="u-left-margin--2x"
                v-if="showAreaFilters"
                type="primary"
                size="small"
                @click="filterAreasByRegion"
                >Filter Areas</el-button
              > -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Select Areas">
              <dropdownSelectAll
                v-model="filterVisits.areas"
                :source="areasList.length ? areasList : lookups.areas"
                :placeholder="$t('handleAdsPagePlaceholderSelectAreas')"
                clearable
                filterable
              >
                <template v-slot:optionBody v-if="!areasList.length">
                  <el-option
                    v-for="item in lookups.areas"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  >
                  </el-option>
                </template>
                <template v-slot:optionBody v-else>
                  <el-option
                    v-for="item in areasList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </template>
              </dropdownSelectAll>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button @click="closeFilterVisitsDialog">Cancel</el-button>
        <el-button
          type="success"
          @click="() => handleExportVisits('view', 'all-visits')"
          >Export</el-button
        >
      </template>
    </el-dialog>
    <a style="display: none" ref="sheet-download" target="_blank"></a>
  </div>
</template>

<script>
import { faturaTeamService } from "../../../../services/faturaTeam.service";
import { utils } from "@/utils";
import { regionsService } from "../../../../services";
import moment from "moment";
import dropdownCategoriesSelect from "@/components/dropdownCategoriesSelect.vue";
import extendedFunctions from "@/utils/extendedFunctions";
import dropdownSelectAll from "@/components/dropdownSelectAll.vue";
import { mapGetters } from "vuex";

export default {
  name: "AgentsSheetsHandler",
  props: {
    chosenFilters: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  components: { dropdownCategoriesSelect, dropdownSelectAll },
  computed: { ...mapGetters({ lookups: "getAllLookups" }) },
  data() {
    return {
      sheetFileList: [],
      visitsSheetFileList: [],
      showFilterVisitsDialog: false,
      filterVisits: {
        startDate: null,
        endDate: null,
        areas: [],
      },
      rules: {
        startDate: [
          { required: true, message: "Start Date Required", trigger: "blur" },
        ],
      },
      regionsList: [],
      regionsId: [],
      areasList: [],
      showAreaFilters: false,
      targetFilters: {
        agentId: "",
        assignmentStartDate: "",
        assignmentEndDate: "",
      },
      targetsSheetFileList: [],
      areasSheetFileList: [],
    };
  },
  methods: {
    async handelDownloadTargets(action, fileName) {
      try {
        for (const key in this.targetFilters) {
          if (!this.targetFilters[key]) {
            delete this.targetFilters[key];
          }
        }
        const response = await faturaTeamService.downloadTarget(
          action,
          this.targetFilters
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, fileName, "xlsx");
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    async handelDownloadAreas(action, fileName) {
      try {
        const response = await faturaTeamService.DownloadAreas(action);
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, fileName, "xlsx");
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    closeFilterVisitsDialog() {
      this.filterVisits = {
        startDate: null,
        endDate: null,
        areas: [],
      };
      this.showFilterVisitsDialog = false;
      this.regionsId = [];
      this.areasList = [];
    },
    regionsChanged(regions) {
      this.showAreaFilters = regions.length && true;
      if (regions.length === 0) {
        this.filterVisits.areas = [];
        this.areasList = [];
      } else {
        this.filterAreasByRegion();
      }
    },
    async filterAreasByRegion() {
      try {
        const {
          data: {
            data: { areas },
          },
        } = await regionsService.getAreasLookup({
          filter: { regionId: this.regionsId },
        });

        this.areasList = areas.map((a) => ({ id: a.key, name: a.value }));
        this.filterVisits.areas = areas.map((a) => a.key);
        this.showAreaFilters = false;
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    async uploadTargetSheetFileChanged(changedData) {
      this.targetsSheetFileList = [changedData];
      try {
        let toBeSentForm = new FormData();
        toBeSentForm.append("file", this.targetsSheetFileList[0].raw);
        let response = await faturaTeamService.importTargetsSheet(toBeSentForm);
        this.$emit("getSheetResult", response.data);
        if (response.data.failed.length === 0) {
          this.$message({
            message: "import file successfully",
            type: "success",
            duration: 2000,
          });
        }
        this.targetsSheetFileList = [];
      } catch {
        this.targetsSheetFileList = [];
      }
    },
    removeSheetFile() {
      this.sheetFileList = [];
      this.visitsSheetFileList = [];
      this.targetsSheetFileList = [];
      this.areasSheetFileList = [];
    },
    async handleSheetImport(chosenAction) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", this.sheetFileList[0].raw);
      try {
        let response = await faturaTeamService.importAgentsSheet(
          toBeSentForm,
          chosenAction
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            let downloadedFileName = "uploadErrorSheet";
            utils.downloadBlob(blob, downloadedFileName, "xlsx");
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
          this.sheetFileList = [];
          setTimeout(() => {
            this.$emit("dataUpdated");
          }, 1500);
        }
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    async handleSheetExport(exportInputObject) {
      const chosenAction = exportInputObject.action;
      try {
        let response = await faturaTeamService.exportAgentsSheet(
          chosenAction,
          this.chosenFilters
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          let downloadedFileName = this.setFileName(exportInputObject);
          utils.downloadBlob(blob, downloadedFileName, "xlsx");
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch {
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    async handleExportVisitsTemplate(action, fileName) {
      try {
        let response = await faturaTeamService.exportVisitsSheet(
          action,
          this.chosenFilters
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, fileName, "xlsx");
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
    async handleExportVisits(action, fileName) {
      this.$refs.filterVisits.validate(async (valid) => {
        if (valid) {
          if (!this.filterVisits.endDate) {
            delete this.filterVisits.endDate;
          }
          let data = {};
          if (this.filterVisits.startDate) {
            data = {
              ...data,
              startDate:
                this.filterVisits.startDate &&
                moment(this.filterVisits.startDate).format("YYYY-MM-DD"),
            };
          }
          if (this.filterVisits.endDate) {
            data = {
              ...data,
              endDate:
                this.filterVisits.endDate &&
                moment(this.filterVisits.endDate).format("YYYY-MM-DD"),
            };
          }
          if (this.filterVisits.areas.length > 0) {
            data = {
              ...data,
              areas: this.filterVisits.areas,
            };
          }
          try {
            let response = await faturaTeamService.exportVisitsSheet(
              action,
              data
            );
            if (response && response.data) {
              const responseData = response.data;
              let blob = new Blob([responseData], {
                type: response.headers["content-type"],
              });
              utils.downloadBlob(blob, fileName, "xlsx");
              this.$message({
                message: "file downloaded successfully",
                type: "success",
                duration: 4000,
              });
              this.closeFilterVisitsDialog();
            }
          } catch (error) {
            this.$message({
              message: "Error Occured",
              type: "error",
              duration: 2000,
            });
          }
        }
      });
    },
    setFileName(inputObject) {
      const chosenAction = inputObject.action;
      const type = inputObject.type;
      let output;
      if (type == "agent") {
        if (chosenAction == "create") output = "Agent_CreateTemplate";
        if (chosenAction == "update") output = "Agents_Export";
      } else {
        if (chosenAction == "assign") output = "Assignation_Export";
        if (chosenAction == "assignTemplate")
          output = "Agent_Assignation_Template";
      }
      return output;
    },
    uploadVisitsSheetFileChanged(changedData, action) {
      this.visitsSheetFileList = [changedData];
      this.handleCheckUploadVisitsSheetFile(action);
    },
    handleCheckUploadVisitsSheetFile(action) {
      if (action !== "append") {
        this.$confirm(
          "Assigning new visits will unassigned all the assigned visits on this user",
          this.$t("DistributerProfilePageDeleteAreaWarningBoxTitle"),
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(async () => {
          this.handleUploadVisitsSheetFile(action);
        });
      } else {
        this.handleUploadVisitsSheetFile(action);
      }
    },
    async handleUploadVisitsSheetFile(action) {
      try {
        let toBeSentForm = new FormData();
        toBeSentForm.append("file", this.visitsSheetFileList[0].raw);
        let response = await faturaTeamService.importVisitsSheet(
          toBeSentForm,
          action
        );
        this.$emit("getSheetResult", response.data);
        if (response.data.failed.length === 0) {
          this.$message({
            message: "import file successfully",
            type: "success",
            duration: 2000,
          });
        }
        this.visitsSheetFileList = [];
      } catch {
        this.visitsSheetFileList = [];
      }
    },
    async handleDownloadUnassignVisits() {
      try {
        const response = await faturaTeamService.downloadUnassignVisits();
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, "unassign-visits-template", "xlsx");
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch {
        this.$message({
          message: "Some error occurred while downloading",
          type: "error",
          duration: 4000,
        });
      }
    },
    async handleUploadUnassignVisits(changedData) {
      this.$confirm(
        "Are you sure you want to unassign ALL VISITS FROM ALL USERS?",
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(async () => {
        try {
          let toBeSentForm = new FormData();
          toBeSentForm.append("file", changedData.raw);
          let response = await faturaTeamService.uploadUnassignVisits(
            toBeSentForm
          );
          this.$emit("getSheetResult", response.data);
          this.$message({
            message: "import file successfully",
            type: "success",
            duration: 2000,
          });
          this.visitsSheetFileList = [];
        } catch (err) {
          this.$message({
            message: err.response.data.message,
            type: "error",
            duration: 4000,
          });
          this.visitsSheetFileList = [];
        }
      });
    },
    async getRegions() {
      try {
        // TODO REMOVE STATIC FIX FROM HERE
        let regionsResponse = await regionsService.getRegions();
        this.regionsList = regionsResponse.data.result.map((r) => {
          if (r.config == undefined) r.config = {};

          r.children = r.childs.map((smallR) => {
            if (smallR.config == undefined) smallR.config = {};
            return {
              label: smallR.name,
              value: smallR.id,
              config: smallR.config,
            };
          });
          return {
            label: r.name,
            value: r.id,
            children: r.children,
            config: r.config,
          };
        });
        return regionsResponse;
      } catch (err) {
        console.log("🚀 ~ getRegions ~ err:", err);
      }
    },
    async handelImportAreas(fileList, action) {
      try {
        const toBeSentForm = new FormData();
        toBeSentForm.append("file", fileList.raw);
        const response = await faturaTeamService.importAgentAreas(
          action,
          toBeSentForm
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.data.message) {
              this.$message({
                message: parsedResponse.data.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "error",
              duration: 4000,
            });
          }
        }
        this.areasSheetFileList = [];
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 3500);
        this.areasSheetFileList = [];
      }
    },
  },
  mounted() {
    this.getRegions();
  },
};
</script>

<style lang="scss">
.buttons-container {
  background-color: white;
  padding: 15px 10px;
  border: 0.5px solid #dcdfe6;
  border-radius: 5px;
}
.el-dropdown-menu {
  .el-upload {
    display: block;
    margin-bottom: 5px;
  }
  .el-button {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    background: transparent;
    border: none;
    padding: 7px 0;
    text-align: left;
    font-size: 14px;
    color: #666;
    &:hover {
      background: transparent;
      // background-color: #fffae7;
      color: #fdd742;
    }
  }
}
</style>
